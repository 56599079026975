import React from "react"
import { Outlet } from "react-router-dom"

// layouts Format
import VerticalLayout from "../components/VerticalLayout/"
import HorizontalLayout from "../components/HorizontalLayout/"

const Layout = props => {
  const getLayout = role => {
    let Layout = role !== "Agency" ? VerticalLayout : HorizontalLayout
    return Layout
  }

  const role = JSON.parse(localStorage.getItem("authUser"))?.role_type

  const Layout = getLayout(role)

  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}

export default Layout
