import { combineReducers } from "@reduxjs/toolkit"

//import slices from Redux Toolkit

//Sample
import { reducer as sampleReduxSliceReducer } from "../slices/sampleReduxSlice"
import { reducer as Agency } from "../slices/Agency"
import { reducer as Product } from "../slices/Product"
import { categoryReducer } from "slices/Category"

//import reducer from PURE Redux
import Ecommerce from "store/e-commerce/reducer"

// Front
import Layout from "../store/layout/reducer"

// Authentication
import Login from "../store/auth/login/reducer"

//Dashboard
import Dashboard from "../store/dashboard/reducer"

const rootReducer = combineReducers({
  sampleReduxSlice: sampleReduxSliceReducer,
  Layout,
  Login,
  Agency,
  Dashboard,
  Product,
  Ecommerce,
  Category: categoryReducer,
})

export default rootReducer
