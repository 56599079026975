import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createCartService, updateCartService, deleteCartService } from "services/agency.service";

export const addToCart = createAsyncThunk('cart', async (item) => {
  return await createCartService(item);
})

// export const getProducts = createAsyncThunk('merchant/getProducts', async () => {
//   return await getProductsService();
// });

// export const createProduct = createAsyncThunk('merchant/createProduct', async (payload) => {
//   return await createProductService(payload);
// });

// export const showProduct = createAsyncThunk('merchant/showProduct', async (id) => {
//   return await showProductService(id);
// });

// export const updateProduct = createAsyncThunk('merchant/updateProduct', async (payload) => {
//   return await updateProductService(payload);
// });

// export const deleteProduct = createAsyncThunk('merchant/deleteProduct', async (id) => {
//   return await deleteProductService(payload);
// });

export const addToCompare = (item) => (dispatch) => {
  dispatch(slice.actions.addToCompare(item))
}

export const clearCompareItems = () => (dispatch) => {
  dispatch(slice.actions.clearCompareItems())
}

const initialState = {
  compareItems: [],
  myCart: [],
  viewProduct: null,
  message: null,
  loading: false,
  success: false,
  error: null,
};

const slice = createSlice({
  name: "Agency",
  initialState,
  reducers: {
    addToCompare(state, action) {
      const itemToAdd = action.payload;

      if (!state.compareItems.some((item) => item.id === itemToAdd.id)) {
        if(state.compareItems.length >= 4)
          return;
        state.compareItems.push(itemToAdd);
      } else {
        state.compareItems = state.compareItems.filter((current) => current.id != itemToAdd.id);
      }
    },
    clearCompareItems(state) {
      state.compareItems = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(addToCart.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.myCart = action.payload;
        state.success = true;
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.error = action.error.message
      })

    // builder
    //   .addCase(getProducts.pending, (state, action) => {
    //     state.loading = true;
    //   })
    //   .addCase(getProducts.fulfilled, (state, action) => {
    //     state.products = action.payload;
    //     state.success = true;
    //   })
    //   .addCase(getProducts.rejected, (state, action) => {
    //     state.error = action.error.message
    //   })

    // builder
    //   .addCase(createProduct.pending, (state, action) => {
    //     state.loading = true;
    //   })
    //   .addCase(createProduct.fulfilled, (state, action) => {
    //     state.product = action.payload;
    //     state.success = true;
    //   })
    //   .addCase(createProduct.rejected, (state, action) => {
    //     state.error = action.error.message
    //   })

    // builder
    //   .addCase(showProduct.pending, (state, action) => {
    //     state.loading = true;
    //   })
    //   .addCase(showProduct.fulfilled, (state, action) => {
    //     state.product = action.payload;
    //     state.success = true;
    //   })
    //   .addCase(showProduct.rejected, (state, action) => {
    //     state.error = action.error.message
    //   })

    // builder
    //   .addCase(updateProduct.pending, (state, action) => {
    //     state.loading = true;
    //   })
    //   .addCase(updateProduct.fulfilled, (state, action) => {
    //     state.product = action.payload;
    //     state.success = true;
    //   })
    //   .addCase(updateProduct.rejected, (state, action) => {
    //     state.error = action.error.message
    //   })

    // builder
    //   .addCase(deleteProduct.pending, (state, action) => {
    //     state.loading = true;
    //   })
    //   .addCase(deleteProduct.fulfilled, (state, action) => {
    //     state.success = true;
    //   })
    //   .addCase(deleteProduct.rejected, (state, action) => {
    //     state.error = action.error.message
    //   })
  }
});

export const { reducer } = slice;

export default slice;

// HOW TO USE 👇

// import {
//   closeModal,
//   openModal,
// } from './slices/modals';
// import { useDispatch, useSelector } from './store';

// ACCESS STATE SLICE
// const {
//   isModalOpen,
// } = useSelector((state) => state.modals);

// TRIGGER DISPATCH / REDUCER FUNCTIONS
//const dispatch = useDispatch();
// dispatch(openModal());
