import { del, get, post, put } from "helpers/api_helper";

const PRODUCTS_BASE_URL = "/products";

export const getProductsService = () => get(PRODUCTS_BASE_URL);
export const showProductService = (id) => get(`${PRODUCTS_BASE_URL}/${id}`);
export const createProductService = product => post(PRODUCTS_BASE_URL, product);
export const updateProductService = product => put(PRODUCTS_BASE_URL, product);
export const deleteProductService = product => del(PRODUCTS_BASE_URL, product);

export const getProductsSpecifications = specifications => get(PRODUCTS_BASE_URL, product);