import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
  getCategoryService,
  getCategoriesService,
  updateCategoryService,
  createCategoryService,
  deleteCategoryService,
} from "services/category.service"

export const getCategories = createAsyncThunk(
  "/getCategories",
  async (params, { rejectWithValue }) => {
    return await getCategoriesService(params, rejectWithValue)
  }
)

export const getCategory = createAsyncThunk(
  "/getCategory",
  async (id, { rejectWithValue }) => {
    return await getCategoryService(id, rejectWithValue)
  }
)

export const addCategory = createAsyncThunk(
  "/addCategory",
  async (params, { rejectWithValue }) => {
    return await createCategoryService(params, rejectWithValue)
  }
)

export const updateCategory = createAsyncThunk(
  "/updateCategory",
  async ({ data, id }, { rejectWithValue }) => {
    return await updateCategoryService(id, data, rejectWithValue)
  }
)

export const deleteCategory = createAsyncThunk(
  "/deleteCategory",
  async (id, { rejectWithValue }) => {
    return await deleteCategoryService(id, rejectWithValue)
  }
)

const initialLog = {
  type: "",
  error: false,
  message: null,
  loading: false,
  success: false,
}

const initialState = {
  category: {},
  categories: [],
  included: [],
  logs: initialLog,
}

const slice = createSlice({
  name: "Category",
  initialState,
  reducers: {
    resetData: state => {
      state = initialState
    },
  },
  extraReducers: {
    [updateCategory.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "updateCategory" }
    },
    [updateCategory.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [updateCategory.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [getCategories.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "getCategories" }
    },
    [getCategories.fulfilled]: (state, action) => {
      state.categories = action.payload.result.data
      state.included = action.payload.result.included
      state.logs = { ...state.categoryLog, success: true, loading: false }
    },
    [getCategories.rejected]: (state, action) => {
      state.logs = {
        ...state.categoryLog,
        error: true,
        loading: false,
      }
    },
    [addCategory.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "addCategory" }
    },
    [addCategory.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [addCategory.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [getCategory.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "getCategory" }
    },
    [getCategory.fulfilled]: (state, action) => {
      state.logs = { ...state.logs, success: true, loading: false }
      state.category = action.payload.result.data
      state.included = action.payload.result.included
    },
    [getCategory.rejected]: (state, action) => {
      state.logs = { ...state.logs, error: true, loading: false }
    },
    [deleteCategory.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "deleteCategory" }
    },
    [deleteCategory.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [deleteCategory.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
  },
})

export const { actions: categoryActions, reducer: categoryReducer } = slice

export const { resetData } = categoryActions

export default slice
