import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider as ReduxProvider } from "react-redux"

import store from "./store"
import rtkStore from "./storeRtk"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <ReduxProvider store={rtkStore}>
    <React.Fragment>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </ReduxProvider>
)
// root.render(
//      <ReduxProvider store={store}>
//     <React.Fragment>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </React.Fragment>
//      </ReduxProvider>
// )

serviceWorker.unregister()
