import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createProductService, deleteProductService, getProductsService, showProductService, updateProductService } from "services/merchant.service";

export const getProducts = createAsyncThunk('/getProducts', async () => {
  return await getProductsService();
});

export const createProduct = createAsyncThunk('/createProduct', async (payload) => {
  return await createProductService(payload);
});

export const showProduct = createAsyncThunk('/showProduct', async (id) => {
  return await showProductService(id);
});

export const updateProduct = createAsyncThunk('/updateProduct', async (payload) => {
  return await updateProductService(payload);
});

export const deleteProduct = createAsyncThunk('/deleteProduct', async (id) => {
  return await deleteProductService(id);
});

const initialState = {
  products: [],
  message: null,
  loading: false,
  product: null,
  success: false,
  error: null,
  actionType: '',
};

const slice = createSlice({
  name: "Product",
  initialState,
  reducers: {
    handleToggleProductActionType(state, action) {
      state.actionType = action.payload;
    },
  },
  extraReducers: {
    [getProducts.pending]: (state) => {
      state.loading = true;
    },
    [getProducts.fulfilled]: (state, action) => {
      state.products = action.payload.result.data;
      state.success = true;
      state.loading = false;
    },
    [getProducts.rejected]: (state, action) => {
      state.error = true
      state.loading = false;
    },
    [createProduct.pending]: (state) => {
      state.loading = true;
    },
    [createProduct.fulfilled]: (state, action) => {
      state.product = action.payload.result.data;
      state.success = true;
    },
    [createProduct.rejected]: (state, action) => {
      state.error = true
      state.loading = false;
    },
    [showProduct.pending]: (state) => {
      state.loading = true;
    },
    [showProduct.fulfilled]: (state, action) => {
      state.product = action.payload.result.data;
      state.success = true;
      state.loading = false;
    },
    [showProduct.rejected]: (state) => {
      state.error = true
      state.loading = false;
    },
  }
});

export const { reducer } = slice;

export const toggleProductActionType = (actionType) => (dispatch) => {
  dispatch(slice.actions.handleToggleProductActionType(actionType));
};

export default slice;
