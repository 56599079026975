import { del, get, post, put } from "helpers/api_helper"

const PRODUCTS_BASE_URL = "/product-categories"

export const getCategoriesService = (params, rejectWithValue) =>
  get(
    PRODUCTS_BASE_URL,
    {
      params: params,
    },
    rejectWithValue
  )

export const createCategoryService = (data, rejectWithValue) =>
  post(PRODUCTS_BASE_URL, data, {}, rejectWithValue)

export const getCategoryService = (id, rejectWithValue) =>
  get(`${PRODUCTS_BASE_URL}/${id}`, {}, rejectWithValue)

export const updateCategoryService = (id, data, rejectWithValue) =>
  put(`${PRODUCTS_BASE_URL}/${id}`, data, {}, rejectWithValue)

export const deleteCategoryService = (id, rejectWithValue) =>
  del(`${PRODUCTS_BASE_URL}/${id}`, {}, rejectWithValue)
