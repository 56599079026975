import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  // eslint-disable-next-line
  TypedUseSelectorHook,
} from "react-redux"
import { configureStore } from "@reduxjs/toolkit"
import rootReducer from "./rootReducer"
import thunk from "redux-thunk"

import createSagaMiddleware from "redux-saga"
import rootSaga from "../store/sagas"

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware, thunk],
})
sagaMiddleware.run(rootSaga)

// Type for useSelector state autocompletion without TypeScript
/**@type TypedUseSelectorHook<ReturnType<typeof store.getState>> */
export const useSelector = useReduxSelector

/**@type typeof store.dispatch */
export const useDispatch = () => useReduxDispatch()

export default store
