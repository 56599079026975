import { get, del, post, put } from "helpers/api_helper";

const AGENCY_URL = "/agencies"
const CART_URL = "/cart"

// AGENCY URLs
export const getAgencyService = () => get(AGENCY_URL)
export const updateAgencyService = agent => put(AGENCY_URL, agent)

// CART URLs
export const createCartService = cartItem => post(CART_URL, cartItem)
export const updateCartService = cartItem => put(CART_URL, cartItem)
export const deleteCartService = cartItem => del(CART_URL, cartItem)
