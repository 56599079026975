import {
  CREATE_PRODUCT_SUCCESSFUL,
  CREATE_PRODUCT_FAILED,
  UPDATE_PRODUCT_SUCCESSFUL,
  UPDATE_PRODUCT_FAILED,
  DELETE_PRODUCT_SUCCESSFUL,
  DELETE_PRODUCT_FAILED,
} from "./actionTypes"

const initialState = {
  products: [],
  message: null,
  loading: false,
  product: null,
  success: false,
  error: null,
}

const Merchant = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PRODUCT_SUCCESSFUL:
    case UPDATE_PRODUCT_SUCCESSFUL:
    case DELETE_PRODUCT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        product: action.payload,
        success: true,
        error: null,
      }
      break
    case CREATE_PRODUCT_FAILED:
    case UPDATE_PRODUCT_FAILED:
    case DELETE_PRODUCT_FAILED:
      state = {
        ...state,
        product: null,
        loading: false,
        success: false,
        error: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Merchant
