import React from "react"
import { Navigate } from "react-router-dom"

const Authmiddleware = props => {
  const token = localStorage.getItem("jwt_token")
  const role = JSON.parse(localStorage.getItem("authUser"))?.role_type
  console.log("Triggered ROLE: ", props.role, role)
  if (token) {
    if (role === props.role) {
      return <React.Fragment>{props.children}</React.Fragment>
    } else {
      // Forbidden
      console.log("Triggered Forbidden")
      return <Navigate to="/403" />
    }
  } else {
    // Authenticate or initialize useEffect in Ctx to ignore log-in UI
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  }
}

export default Authmiddleware
