import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = localStorage.getItem("jwt_token")

//apply base url for axios
const API_URL = process.env.REACT_APP_API

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}, rejectWithValue) {
  return await axiosApi
    .get(url, { ...config })
    .then(response => response.data)
    .catch(error => rejectWithValue(error.response.data))
}

export async function post(url, data, config = {}, rejectWithValue) {
  return axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
    .catch(error => rejectWithValue(error.response.data))
}

export async function put(url, data, config = {}, rejectWithValue) {
  return axiosApi
    .put(url, data, { ...config })
    .then(response => response.data)
    .catch(error => rejectWithValue(error.response.data))
}

export async function del(url, config = {}, rejectWithValue) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
    .catch(error => rejectWithValue(error.response.data))
}
